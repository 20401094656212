import logoConexion from "./../../assets/img/logo_conexion_letras_blancas.png";
import insertrans from "./../../assets/img/INSERTRANS.png";
import svNet from "./../../assets/img/SVNet.png";
import ticongleAgency from "./../../assets/img/Agency_i.png";
import ticongleAcademy from "./../../assets/img/Academy.png";
import IncubatorWhite from "../../assets/img/Incubator_white.png";
import PumaEnergyFoundation from "./../../assets/img/Logo_Puma_energy.png";
import Hakki from "./../../assets/img/hakki.png";
import Mido from "./../../assets/img/mido.png";
import IMJ from "./../../assets/img/IMJ.png";
import LEGALITIKA from "./../../assets/img/legalitika.png";
import UniversidadJoseMatiasDelgado from "./../../assets/img/universidadJoseMatiasDelgado.png";
import Findex from "./../../assets/img/FINDEX.png";
import CapaUno from "./../../assets/img/CapaUno.png";
import Kanecode from "./../../assets/img/Kanecode.png";
import Norttech from "./../../assets/img/Norttech.png";
import Tiankii from "./../../assets/img/Tiankii.png";
import MINEC from "./../../assets/img/Minec.png";
import UDB from "./../../assets/img/UDB.png";
import FocusCentralAmerica from "./../../assets/img/FocusCentralAmerica.png";
import UTEC from "./../../assets/img/UTEC.png";
import RedIH from "./../../assets/img/RedIH.png";
import capro from "./../../assets/img/logo-capro.png";
import AracariStudios from "./../../assets/img/AracariStudios.png";
import ATSGROUP from "./../../assets/img/ATSGROUP.png";
import USAM from "./../../assets/img/USAM.png";
import UPED from "./../../assets/img/UPED.png";
import GrupoPubliciti from "./../../assets/img/GrupoPubliciti.png";
import Ditobanx from "./../../assets/img/ditobanx-white.svg";
import Glasswing from "./../../assets/img/Glasswing.png";
import ESIAP from "./../../assets/img/ESIAP.png";
import jaelsalvador from "./../../assets/img/JuniorAchievement.png";
import Outside from "./../../assets/img/logoOutside1080x1080blanco.png";

const Patrocinadores = () => {
  return (
    <div className="container mx-auto pt-8 pb-14 mt-20 md:mt-52">
      <div className="flex flex-wrap">
        <div className="w-full">
          <h1
            data-aos="zoom-in"
            className="text-white text-3xl lg:text-4xl xl:text-5xl font-semibold text-center md:text-start md:ml-5"
          >
            Organizado por
          </h1>
          <div className="flex flex-wrap">
            <a
              href="https://www.conexion.sv/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/2 mt-5 p-3 flex justify-center sm:justify-end"
            >
              <img
                data-aos="zoom-in"
                src={logoConexion}
                className="w-2/4"
                alt="logoConexion"
              />
            </a>
            <a
              href="http://www.innovacion.cc/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/2 mt-5 p-3 flex justify-center sm:justify-start"
            >
              <img
                data-aos="zoom-in"
                src={insertrans}
                className="w-2/4"
                alt="insertrans"
              />
            </a>
          </div>
        </div>
        <div className="border w-full my-20 opacity-20 md:hidden"></div>
        <h1
          data-aos="zoom-in"
          className="text-white text-3xl lg:text-4xl xl:text-5xl font-semibold text-center md:text-start md:mt-20 md:ml-5 w-full"
        >
          Con el apoyo de
        </h1>
        <div className="w-full mt-10 fondo-patrocinadores rounded-lg py-5 mx-5">
          <div className="flex flex-wrap justify-center">
            {/* Categoría 1 */}
            <a
              href="https://www.economia.gob.sv/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-full sm:w-1/3 flex justify-center items-center mt-5 py-3"
              data-aos="zoom-in"
            >
              <div className="bg-azulOscuroGob rounded-lg flex justify-center items-center w-3/5 sm:w-3/5 px-3 py-1">
                <img src={MINEC} className="" alt="MINEC" />
              </div>
            </a>
            <a
              href="https://pumaenergyfoundation.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/3 flex justify-center items-center mt-5 py-3"
            >
              <div className="rounded-lg flex justify-center items-center w-3/5 sm:w-3/5 h-full">
                <img
                  data-aos="zoom-in"
                  className="h-full"
                  src={PumaEnergyFoundation}
                  alt="PumaEnergyFoundation"
                />
              </div>
            </a>
            <a
              href="https://www.facebook.com/IMJSanSalvador/?locale=es_LA"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/3 flex justify-center items-center mt-5 py-3"
            >
              <div className="rounded-lg flex justify-center items-center w-3/5 sm:w-4/5 h-full">
                <img
                  data-aos="zoom-in"
                  className="w-full"
                  src={IMJ}
                  alt="IMJ"
                />
              </div>
            </a>
            <a
              href="https://glasswing.org/es/glasswing-el-salvador/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/3 flex justify-center items-center mt-5 py-3"
            >
              <div className="rounded-lg flex justify-center items-center w-3/5 sm:w-3/5 h-full">
                <img data-aos="zoom-in" src={Glasswing} alt="Glasswing" />
              </div>
            </a>
            <a
              href="https://www.focuscentralamerica.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/3 flex justify-center items-center mt-5 py-3"
            >
              <div className="rounded-lg flex justify-center items-center w-3/5 sm:w-3/5 h-full">
                <img
                  data-aos="zoom-in"
                  src={FocusCentralAmerica}
                  alt="FocusCentralAmerica"
                />
              </div>
            </a>
            <div
              className="w-full sm:w-1/3 flex justify-center items-center mt-5 py-3"
              data-aos="zoom-in"
            >
              <div className="p-5 bg-white rounded-lg flex justify-center items-center w-3/5 sm:w-3/5 h-full">
                <img src={GrupoPubliciti} alt="GrupoPubliciti" />
              </div>
            </div>
            {/* Categoría 1 */}
          </div>
          <div className="flex flex-wrap justify-center">
            {/* Categoría 2 */}
            <a
              href="https://svnet.sv/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/5 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-3/6 sm:w-4/6"
                src={svNet}
                alt="svNet"
              />
            </a>
            <div
              className="w-full sm:w-1/5 flex justify-center items-center mt-5 py-3"
              data-aos="zoom-in"
            >
              <div className="bg-white rounded-lg flex justify-center items-center w-3/6 sm:w-4/6 px-3 py-1 h-full ">
                <img src={ESIAP} className="my-4" alt="ESIAP" />
              </div>
            </div>
            <a
              /*     href="https://ticongle.sv/" */
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/5 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-3/6 sm:w-4/6"
                src={IncubatorWhite}
                alt="IncubatorWhite"
              />
            </a>
            <a
              href="https://ticongle.sv/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/5 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-3/6 sm:w-4/6"
                src={ticongleAcademy}
                alt="ticongleAcademy"
              />
            </a>
            <a
              href="https://ticongle.agency/#/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-1/5 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-3/6 sm:w-4/6"
                src={ticongleAgency}
                alt="ticongleAgency"
              />
            </a>
            {/* Categoría 2 */}
          </div>
          <div className="flex flex-wrap sm:justify-center">
            {/* Categoría 3 */}
            <a
              href="https://www.ujmd.edu.sv/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={UniversidadJoseMatiasDelgado}
                alt="UniversidadJoseMatiasDelgado"
              />
            </a>
            <a
              href="https://www.udb.edu.sv/udb/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-3/6 sm:w-3/6"
                src={UDB}
                alt="UDB"
              />
            </a>
            <a
              href="https://www.utec.edu.sv/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-full"
                src={UTEC}
                alt="UTEC"
              />
            </a>
            <a
              href="https://www.usam.edu.sv/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-3/6 sm:w-3/6"
                src={USAM}
                alt="USAM"
              />
            </a>
            <a
              href="https://www.pedagogica.edu.sv/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-3/6 sm:w-5/6"
                src={UPED}
                alt="UPED"
              />
            </a>
            <a
              href="https://jaelsalvador.org/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/6 sm:w-4/6"
                src={jaelsalvador}
                alt="jaelsalvador"
              />
            </a>
            <a
              href="https://ditobanx.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={Ditobanx}
                alt="Ditobanx"
              />
            </a>
            <a
              href="https://www.kanecode.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={Kanecode}
                alt="Kanecode"
              />
            </a>
            <a
              href="https://www.legalitika.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={LEGALITIKA}
                alt="LEGALITIKA"
              />
            </a>
            <a
              href="https://findex.la/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={Findex}
                alt="Findex"
              />
            </a>
            <a
              href="https://sansalvador.impacthub.net/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5"
                src={RedIH}
                alt="RedIH"
              />
            </a>
            <a
              href="https://capauno.net/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={CapaUno}
                alt="CapaUno"
              />
            </a>
            <a
              href="https://www.norttech.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={Norttech}
                alt="Norttech"
              />
            </a>
            <a
              href="https://www.tiankii.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={Tiankii}
                alt="Tiankii"
              />
            </a>
            <div
              rel="noopener noreferrer"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={Mido}
                alt="Mido"
              />
            </div>
            <div className="w-1/2 sm:w-1/6 mt-2 p-3 flex justify-center items-center">
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={Hakki}
                alt="Hakki"
              />
            </div>
            <a
              href="https://capro.sv/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 flex justify-center items-center mt-5"
              data-aos="zoom-in"
            >
              <div className="bg-white rounded-lg sm:py-3 flex justify-center items-center w-4/5 sm:w-5/6 h-2/3">
                <img className="w-full" src={capro} alt="capro" />
              </div>
            </a>
            <a
              href="https://www.aracaristudios.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-full"
                src={AracariStudios}
                alt="AracariStudios"
              />
            </a>
            <a
              href="https://atsgroup.tech"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/4"
                src={ATSGROUP}
                alt="ATSGROUP"
              />
            </a>
            <a
              href="https://www.outsidetechco.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 sm:w-1/6 mt-5 p-3 flex justify-center items-center"
            >
              <img
                data-aos="zoom-in"
                className="w-4/5 sm:w-3/5"
                src={Outside}
                alt="Outside"
              />
            </a>
            {/* Categoría 3 */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Patrocinadores;
