import { useEffect, useState } from 'react';
import lineaPunteada from './../../assets/img/lineaPunteada.svg';
import ModalForm from '../ModalForm/ModalForm';
import { useGlobalState } from '../GlobalState/GlobalState';

const CardsNumeros = () => {

    const { globalState, setGlobalState } = useGlobalState();

    const ShowModal = () => {
        // Modifica el estado global
        setGlobalState({ ...globalState, valor: true });
    };

    return(
        <>
            <div class="container mx-auto py-8 mt-20 md:mt-52">
                <div class="flex flex-wrap relative">
                    <div data-aos="zoom-in" class="relative w-full sm:w-1/2 md:w-1/4 flex flex-wrap justify-center mt-5 z-10">
                        <div 
                        // onClick={ShowModal} 
                        class="w-20 md:w-24 h-20 md:h-24 bg-naranja rounded-full flex items-center justify-center cursor-pointer">
                            <span class="text-white text-4xl font-semibold">1</span>
                        </div>
                        <span className="w-full text-2xl lg:text-3xl font-semibold flex justify-center p-2 text-white">Envía tu idea</span>
                        <div className="w-full flex flex-wrap justify-center p-2 text-white">
                            <span className="text-naranja font-extrabold w-full text-center md:text-start">
                                APÚNTATE HASTA
                                <br />
                                <span className="text-white font-normal">12 de noviembre</span>
                            </span>
                            <span className="text-naranja mt-3 font-extrabold w-full text-center md:text-start">
                                SELECCIÓN DE IDEAS
                                <br />
                                <span className="text-white font-normal">13-15 de noviembre</span>
                            </span>
                            <span className="text-naranja mt-3 font-extrabold w-full text-center md:text-start">
                                ANUNCIO DE SELECCIONADOS
                                <br />
                                <span className="text-white font-normal">17 de noviembre</span>
                            </span>
                        </div>
                    </div>
                    <div data-aos="zoom-in" class="relative w-full sm:w-1/2 md:w-1/4 flex flex-wrap justify-center mt-5 z-10">
                        <div
                        // onClick={ShowModal} 
                        class="w-20 md:w-24 h-20 md:h-24 bg-naranja rounded-full flex items-center justify-center cursor-pointer">
                            <span class="text-white text-4xl font-semibold">2</span>
                        </div>
                        <h1 className="w-full text-2xl lg:text-3xl font-semibold flex justify-center p-2 text-white">Workshop</h1>
                        <div className="w-full flex flex-wrap justify-center p-2 text-white">
                            <span className="text-naranja font-extrabold w-full text-center md:text-start">
                                IDEA, PRETOTIPO Y PROTOTIPO
                                <br />
                                <span className="text-white font-normal">28 de noviembre</span>
                            </span>
                            <span className="text-naranja mt-3 font-extrabold w-full text-center md:text-start">
                                HOW TO PITCH
                                <br />
                                <span className="text-white font-normal">30 de noviembre y 7 de diciembre</span>
                            </span>
                            <span className="text-naranja mt-3 font-extrabold w-full text-center md:text-start">
                                MITOS Y REALIDADES DE LA INNOVACIÓN
                                <br />
                                <span className="text-white font-normal">5 de diciembre</span>
                            </span>
                            {/* <span className="text-naranja mt-3 font-extrabold w-full text-center md:text-start">
                                DESDE LA IDEA AL MERCADO
                                <br />
                                <span className="text-white font-normal">7 de diciembre</span>
                            </span> */}
                        </div>
                    </div>
                    <div data-aos="zoom-in" class="relative w-full sm:w-1/2 md:w-1/4 flex flex-wrap justify-center mt-5 z-10">
                        <div
                        // onClick={ShowModal} 
                        class="w-20 md:w-24 h-20 md:h-24 bg-naranja rounded-full flex items-center justify-center cursor-pointer">
                            <span class="text-white text-4xl font-semibold">3</span>
                        </div>
                        <span className="w-full text-2xl lg:text-3xl font-semibold flex justify-center p-2 text-white">Hackfest</span>
                        <div className="w-full flex flex-wrap justify-center p-2 text-white">
                            <span className="text-naranja font-extrabold w-full text-center md:text-start">
                                ATERRIZAJE DE IDEAS
                            </span>
                            <span className="text-naranja font-extrabold w-full text-center md:text-start uppercase">
                                Mentoría
                            </span>
                            <span className="text-naranja font-extrabold w-full text-center md:text-start">
                                NETWORKING
                            </span>
                            <span className="text-naranja font-extrabold w-full text-center md:text-start">
                                PITCH
                            </span>
                            <span className="text-naranja font-extrabold w-full text-center md:text-start">
                                IDEAS GANADORAS
                                <br />
                                <span className="text-white font-normal">09 - 10 de diciembre</span>
                            </span>
                        </div>
                    </div>
                    <div data-aos="zoom-in" class="relative w-full sm:w-1/2 md:w-1/4 flex flex-wrap justify-center mt-5 z-10">
                        <div
                        // onClick={ShowModal} 
                        class="w-20 md:w-24 h-20 md:h-24 bg-moradoClaro rounded-full flex items-center justify-center cursor-pointer">
                            <span class="text-white text-4xl font-semibold">4</span>
                        </div>
                        <span className="w-full text-2xl lg:text-3xl font-semibold flex justify-center p-2 text-white">Incubación</span>
                        <span className="text-naranja font-extrabold w-full text-center md:text-start">
                                DESARROLLO DE LA IDEA
                        </span>
                        <span className="text-naranja font-extrabold w-full text-center md:text-start">
                            COWORKING 
                        </span>
                        <span className="text-naranja font-extrabold w-full text-center md:text-start">
                            FORMACIÓN
                        </span>
                        <span className="text-naranja font-extrabold w-full text-center md:text-start">
                            MENTORIA
                            <br />
                            <span className="text-white font-normal">De enero a junio 2024</span>
                        </span>
                    </div>
                    <img data-aos="zoom-in" src={lineaPunteada} className='absolute -top-5 2xl:-top-10 w-4/5 left-0 right-0 m-auto hidden md:block' alt="" />
                </div>
            </div>
        </>
    )
}

export default CardsNumeros;