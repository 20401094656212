import { faLocationDot, faMap, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'animate.css';

const PreguntasFrecuentes = () => {
    return (
        <>
            <div className="flex flex-wrap mt-20 lg:mt-32 mb-20 lg:mb-32 container mx-auto py-8">
                <div data-aos="zoom-in" className="w-full lg:w-1/2 flex justify-center items-center text-center lg:text-start">
                    <h1 className="text-5xl md:text-5xl font-bold text-white mb-10 lg:mb-0">
                        Preguntas
                        <br />
                        frecuentes
                    </h1>
                </div>
                <div className="w-full lg:w-1/2 flex justify-center items-center text-center md:text-start flex-wrap p-5">
                    <section className="w-full">
                        <dl data-aos="zoom-in" class="mt-2 divide-y divide-slate-100">
                            <details class="group py-4 marker:content-['']">
                                <summary class="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-white group-open:text-white [&amp;::-webkit-details-marker]:hidden">
                                    ¿Qué es el Hackfest?
                                    <svg class="ml-4 mt-0.5 h-6 w-6 flex-none stroke-white group-open:text-white" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M18 12H6"></path>
                                        <path class="group-open:hidden" d="M12 6v12"></path>
                                    </svg>
                                </summary>
                                <div class="pb-6 pt-6 text-white animate__animated animate__fadeIn">
                                    <div class="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-white hover:prose-a:text-indigo-500">
                                        <p className="text-justify">
                                            Es un evento del tipo hackaton que realiza CONEXION El Salvador cada 2 años, desde el año 2013. El objetivo es desarrollar soluciones colaborativas, basadas en tecnología, que respondan a necesidades y problemáticas de actualidad.
                                        </p>
                                    </div>
                                </div>
                            </details>
                        </dl>
                        <dl data-aos="zoom-in" class="mt-2 divide-y divide-slate-100">
                            <details class="group py-4 marker:content-['']">
                                <summary class="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-white group-open:text-white [&amp;::-webkit-details-marker]:hidden">
                                    ¿En qué consiste el Hackfest 2023?
                                    <svg class="ml-4 mt-0.5 h-6 w-6 flex-none stroke-white group-open:text-white" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M18 12H6"></path>
                                        <path class="group-open:hidden" d="M12 6v12"></path>
                                    </svg>
                                </summary>
                                <div class="pb-6 pt-6 text-white animate__animated animate__fadeIn">
                                    <div class="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-white hover:prose-a:text-indigo-500">
                                        <p className="text-justify">
                                            Queremos encontrar ideas innovadoras que hagan uso de tecnología digital para invertir en ellas. Los participantes presentarán sus ideas más ambiciosas con el objetivo de lanzarse a la aventura del emprendimiento.
                                        </p>
                                    </div>
                                </div>
                            </details>
                        </dl>
                        <dl data-aos="zoom-in" class="mt-2 divide-y divide-slate-100">
                            <details class="group py-4 marker:content-['']">
                                <summary class="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-white group-open:text-white [&amp;::-webkit-details-marker]:hidden">
                                    ¿Cuándo y dónde se realizará el Hackfest 2023?
                                    <svg class="ml-4 mt-0.5 h-6 w-6 flex-none stroke-white group-open:text-white" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M18 12H6"></path>
                                        <path class="group-open:hidden" d="M12 6v12"></path>
                                    </svg>
                                </summary>
                                <div class="pb-6 pt-6 text-white animate__animated animate__fadeIn">
                                    <div class="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-white hover:prose-a:text-indigo-500">
                                        <p className="text-justify">
                                            El Hackfest se llevará a cabo de forma presencial en el <a href="https://maps.app.goo.gl/ub6EfPDQtmPgPns87" target="_blank" rel="noreferrer" className="text-white underline">Edificio Juventud Glasswing</a> de San Salvador, los días sábado 9 y domingo 10 de diciembre de 2023. El horario será desde las 8:00 a.m. del sábado hasta las 5:00 p.m. del domingo, un total de +30 horas corridas de maratón.
                                            <br />
                                            <br />
                                            Previo al Hackfest se realizarán actividades de preparación tipo conferencias y talleres, las cuales serán de forma virtual (en línea).
                                        </p>
                                    </div>
                                </div>
                            </details>
                        </dl>
                        <dl data-aos="zoom-in" class="mt-2 divide-y divide-slate-100">
                            <details class="group py-4 marker:content-['']">
                                <summary class="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-white group-open:text-white [&amp;::-webkit-details-marker]:hidden">
                                    ¿Quiénes pueden apuntarse para participar?
                                    <svg class="ml-4 mt-0.5 h-6 w-6 flex-none stroke-white group-open:text-white" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M18 12H6"></path>
                                        <path class="group-open:hidden" d="M12 6v12"></path>
                                    </svg>
                                </summary>
                                <div class="pb-6 pt-6 text-white animate__animated animate__fadeIn">
                                    <div class="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-white hover:prose-a:text-indigo-500">
                                        <p className="text-justify">
                                            Todas las personas salvadoreñas mayores de 18 años al momento de realizarse el Hackfest, que cuenten con una idea basada en uso de tecnología y que tengan las ganas y estén dispuestas a iniciar un emprendimiento. 
                                            <br />
                                            <br />
                                            No necesariamente tienes que ser programador, diseñador o experto en tecnología, lo que necesitas para apuntarte es tener una idea innovadora cuya solución lleve a crear un producto con base tecnológica (un videojuego, una app, un hardware o dispositivo, un software, aplicación o plataforma web, un robot, etc.). 
                                            <br />
                                            <br />
                                            Si tu idea es ganadora, nosotros invertiremos en ella para que la puedas materializar como un emprendimiento, por lo tanto, no necesariamente tienes que ser un experto en creación de tecnología.
                                        </p>
                                    </div>
                                </div>
                            </details>
                        </dl>
                        <dl data-aos="zoom-in" class="mt-2 divide-y divide-slate-100">
                            <details class="group py-4 marker:content-['']">
                                <summary class="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-white group-open:text-white [&amp;::-webkit-details-marker]:hidden">
                                    ¿Cuál es el costo para participar?
                                    <svg class="ml-4 mt-0.5 h-6 w-6 flex-none stroke-white group-open:text-white" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M18 12H6"></path>
                                        <path class="group-open:hidden" d="M12 6v12"></path>
                                    </svg>
                                </summary>
                                <div class="pb-6 pt-6 text-white animate__animated animate__fadeIn">
                                    <div class="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-white hover:prose-a:text-indigo-500">
                                        <p className="text-justify">
                                            Ninguno! Durante el Hackfest los participantes tendrán sin ningún costo: desayunos, almuerzos, cena, meriendas, kit del participante, acceso a Internet y a computadora/laptop en caso que no tengan (si tienen, la llevan). 
                                            <br />
                                            <br />
                                            Los participantes solamente tendrán que cubrir los costos de transporte de ida y regreso al lugar donde se realizará el Hackfest.
                                        </p>
                                    </div>
                                </div>
                            </details>
                        </dl>
                        <dl data-aos="zoom-in" class="mt-2 divide-y divide-slate-100">
                            <details class="group py-4 marker:content-['']">
                                <summary class="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-white group-open:text-white [&amp;::-webkit-details-marker]:hidden">
                                    ¿Cómo se seleccionarán las ideas ganadoras?
                                    <svg class="ml-4 mt-0.5 h-6 w-6 flex-none stroke-white group-open:text-white" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M18 12H6"></path>
                                        <path class="group-open:hidden" d="M12 6v12"></path>
                                    </svg>
                                </summary>
                                <div class="pb-6 pt-6 text-white animate__animated animate__fadeIn">
                                    <div class="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-white hover:prose-a:text-indigo-500">
                                        <p className="text-justify">
                                            La selección de las ideas ganadoras para invertir en ellas estará a cargo de un Jurado conformado por expertos, emprendedores y fundadores de startups. 
                                            <br />
                                            <br />
                                            El Jurado tendrá en cuenta criterios relacionados entorno a las ideas, tales como: originalidad, diferenciación, viabilidad técnica, propuesta de valor, necesidad del mercado, ventaja competitiva, escalabilidad, potencial de mercado, crecimiento, experiencia y talento del equipo.
                                        </p>
                                    </div>
                                </div>
                            </details>
                        </dl>
                        <dl data-aos="zoom-in" class="mt-2 divide-y divide-slate-100">
                            <details class="group py-4 marker:content-['']">
                                <summary class="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-white group-open:text-white [&amp;::-webkit-details-marker]:hidden">
                                    ¿Cuáles son las fechas claves?
                                    <svg class="ml-4 mt-0.5 h-6 w-6 flex-none stroke-white group-open:text-white" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M18 12H6"></path>
                                        <path class="group-open:hidden" d="M12 6v12"></path>
                                    </svg>
                                </summary>
                                <div class="pb-6 pt-6 text-white animate__animated animate__fadeIn">
                                    <div class="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-white hover:prose-a:text-indigo-500">
                                        <p className="text-justify">
                                        12 de noviembre: Fecha límite para que te apuntes con tu idea.
                                        <br />
                                        <br />
                                        17 de noviembre: Notificación de las ideas seleccionadas para participar en el Hackfest.
                                        <br />
                                        <br />
                                        Del 20 de noviembre al 7 de diciembre: Se realizarán de forma virtual (en línea) actividades previas tipo informativas, conferencias y talleres con los participantes de las ideas seleccionadas.
                                        <br />
                                        <br />
                                        9 y 10 de diciembre: Desarrollo del Hackfest 2023 en forma presencial con los participantes de las ideas seleccionadas. Aquí mismo se anunciarán las ideas ganadoras.
                                        <br />
                                        <br />
                                        De enero a junio de 2024: Se llevará a cabo el proceso de incubación para el desarrollo de la ideas ganadoras con mira a generar el producto de lanzamiento al mercado.
                                        </p>
                                    </div>
                                </div>
                            </details>
                        </dl>
                    </section>
                    {/* <div className="w-full mt-5">
                        <a href='Terminos_bases_y_condiciones-HF2023.pdf' data-aos="zoom-in" className='text-white bg-naranja rounded-full text-xl md:text-lg px-5 py-3'>Bases sobre el Hackfest</a>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default PreguntasFrecuentes;