import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imgCirculo from './../../assets/img/esfera.svg';
import imgForma2 from './../../assets/img/forma2.png';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useGlobalState } from '../GlobalState/GlobalState';

const Header = () => {
    const { globalState, setGlobalState } = useGlobalState();

    const ShowModal = () => {
        // Modifica el estado global
        setGlobalState({ ...globalState, valor: true });
    };

    return (
        <>
            <div className='relative'>
                <div className="flex flex-wrap justify-center mt-32 md:mt-48 mx-auto py-8">
                    <div data-aos="fade-right" className="w-full lg:w-1/2 flex justify-center lg:justify-end items-center flex-wrap md:text-left text-center">
                        <h1 className="text-5xl lg:text-6xl xl:text-7xl font-semibold md:ml-5">
                            <span className="text-orange-500">Queremos</span>
                            <br />
                            <span className='text-white'>invertir en tu idea</span>
                            <h1 className='text-white text-lg md:text-xl font-medium leading-none mb-5 mt-10 lg:mt-5'>Invertiremos y trabajaremos juntos para hacer realidad tu idea.</h1>
                            <span className='text-white hidden lg:block bg-moradoOscuro text-xl md:text-2xl p-1 md:p-2 font-normal'><FontAwesomeIcon icon={faCalendar} />&nbsp;&nbsp;09 - 10 diciembre 2023</span>
                        </h1>
                    </div>
                    <div data-aos="fade-left" className="w-1/2 hidden lg:flex justify-center items-center">
                        <img src={imgCirculo} className='w-2/3 opacity-60 animation-spin-slow' alt="Header" />
                    </div>
                    {/* <div className="relative mt-10">
                        <a onClick={ShowModal} data-aos="zoom-in" className='text-white bg-naranja rounded-full px-5 py-2 md:px-10 md:py-4 text-xl md:text-3xl cursor-pointer'>Apúntate</a>
                        <span data-aos="zoom-in" class={`animate-ping absolute top-0 right-0 inline-flex h-4 w-4 rounded-full bg-orange-400 opacity-75`}></span>
                    </div> */}
                </div>
                <img src={imgForma2} className='imgForma2' alt="" />
                <center><span className='text-white lg:hidden bg-moradoOscuro text-xl md:text-3xl p-1 md:p-2 font-normal'><FontAwesomeIcon icon={faCalendar} />&nbsp;&nbsp;09 - 10 diciembre 2023</span></center>
            </div>
        </>
    );
};

export default Header;