const CardsPlanificacion = () => {
    return (
        <>
            <div className="flex flex-wrap mt-20 md:mt-52 container mx-auto py-8">
                <div className="w-full lg:w-1/2 bg-black p-5 ">
                    <h1 data-aos="fade-up" className="text-start text-8xl text-white font-bold">09</h1>
                    <h1 data-aos="fade-up" className="text-start text-4xl text-white my-10">
                        DICIEMBRE <br />2023
                    </h1>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-naranja p-3">
                                <div className="text-center text-md text-white font-semibold">8:00 am - 9:00 am</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Registro y desayuno de bienvenida</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-naranja p-3">
                                <div className="text-center text-md text-white font-semibold">9:15 am - 12:00 md</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Apertura y presentación de ideas</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-naranja p-3">
                                <div className="text-center text-md text-white font-semibold">12:15 pm - 1:15 pm</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Almuerzo y networking</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-naranja p-3">
                                <div className="text-center text-md text-white font-semibold">1:30 pm - 4:30 pm</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Aterrizaje de ideas y mentoría</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-naranja p-3">
                                <div className="text-center text-md text-white font-semibold">4:45 pm - 6:00 pm</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Emprendimiento y creación de startups exitosas</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3 mb-10">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-naranja p-3">
                                <div className="text-center text-md text-white font-semibold">6:00 pm - al amanecer</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Aterrizaje continuo de ideas y cena</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/2 bg-moradoClaro p-5 ">
                    <h1 data-aos="fade-up" className="text-start text-8xl text-white font-bold">10</h1>
                    <h1 data-aos="fade-up" className="text-start text-4xl text-white my-10">
                        DICIEMBRE <br />2023
                    </h1>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-black p-3">
                                <div className="text-center text-md text-white font-semibold">7:00 am - 9:45 am</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Inspiración matutina y desayuno</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-black p-3">
                                <div className="text-center text-md text-white font-semibold">10:00 am - 12:30 pm</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Preparación para el pitch</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-black p-3">
                                <div className="text-center text-md text-white font-semibold">12:30 pm - 1:30 pm</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Almuerzo y networking</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-black p-3">
                                <div className="text-center text-md text-white font-semibold">2:00 pm - 4:30 pm</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Pitch finales</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-black p-3">
                                <div className="text-center text-md text-white font-semibold">4:30 pm - 5:00 pm</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Deliberación del jurado</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-3 mb-10">
                        <div data-aos="zoom-in" className="w-full sm:w-2/5 py-2">
                            <div className="rounded-lg bg-black p-3">
                                <div className="text-center text-md text-white font-semibold">5:00 pm</div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="w-full sm:w-3/5 pl-5 flex items-center">
                            <div className="">
                                <div className="text-start text-md text-white">Anuncio de ideas ganadoras y cierre</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardsPlanificacion;