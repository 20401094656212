// GlobalState.js
import React, { createContext, useContext, useState } from 'react';

// Crear el contexto
const GlobalStateContext = createContext();

// Crear un proveedor del contexto
export function GlobalStateProvider({ children }) {
  const [globalState, setGlobalState] = useState(initialState);

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
}

// Crear un hook personalizado para acceder al contexto
export function useGlobalState() {
  return useContext(GlobalStateContext);
}

// Define un estado inicial si es necesario
const initialState = {
  // ... inicializa tus valores iniciales aquí ...
    valor: false,
};
