import { useEffect } from "react";
import CardsNumeros from "../CardsNumeros/CardsNumeros";
import CardsPlanificacion from "../CardsPlanificacion/CardsPlanificacion";
import Header from "../Header/Header";
import IdeasGanadoras from "../IdeasGanadoras/IdeasGanadoras";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../Footer/Footer";
import Patrocinadores from "../Patrocinadores/Patrocinadores";
import PreguntasFrecuentes from "../PreguntasFrecuentes/PreguntasFrecuentes";
import ModalForm from "../ModalForm/ModalForm";
import CookieConsent from "react-cookie-consent";

const Home = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <>
            <Header />
            <CardsNumeros />
            <IdeasGanadoras />
            <CardsPlanificacion />
            <PreguntasFrecuentes />
            <div className="border w-full my-20 opacity-20 md:hidden"></div>
            <Patrocinadores />
            <Footer />
            <ModalForm />
            <CookieConsent
                className="text-center relative"
                contentClasses="text-center"
                containerClasses="text-center"
                buttonWrapperClasses="cookie-message"
                location="bottom"
                buttonText="Aceptar"
                cookieName="hackfest-cookie"
                style={{ background: "#0e0e0ebf", textAlign: "center" }}
                buttonStyle={{ color: "white", fontSize: "13px", background: "#FA5906", borderRadius: "15px" }}
                expires={15}
            >
                <h1 className="text-center text-white">Utilizamos cookies para optimizar nuestro sitio web y nuestro servicio.</h1>
            </CookieConsent>
        </>
    );
}

export default Home;