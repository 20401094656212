import { useState } from 'react';
import logoHackfest from './../../assets/img/logoHackfest.png';
import { useGlobalState } from '../GlobalState/GlobalState';

const Nav = () => {

    const { globalState, setGlobalState } = useGlobalState();

    const ShowModal = () => {
        // Modifica el estado global
        setGlobalState({ ...globalState, valor: true });
    };

    const [state, setState] = useState(false);

    // Funcion que hace aparecer el boton hasta que se haga scroll
    window.onscroll = function() {
        scrollFunction()
    };

    function scrollFunction() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            setState(true);
        } else {
            setState(false);
        }
    }

    return (
        <nav class="flex flex-wrap justify-center md:justify-between md:pl-5 py-4 w-full fixed top-0 left-0 right-0 z-50 bg-black">
            <div class="flex justify-center md:justify-start p-3">
                <img src={logoHackfest} className='w-auto h-14 md:h-16' alt="" />
            </div>
            {/* <div class="justify-center items-center p-3 hidden md:flex">
                <div className='relative'>
                    <a data-aos="zoom-in" className={`text-white bg-naranja cursor-pointer rounded-full text-xl md:text-lg px-5 py-3 ${state ? "block" : "hidden"}`} onClick={ShowModal}>
                        Apúntate
                    </a>
                    <span class={`animate-ping absolute top-0 right-0 inline-flex h-4 w-4 rounded-full bg-orange-400 opacity-75 ${state ? "block" : "hidden"}`}></span>
                </div>
            </div> */}
        </nav>
    );
};

export default Nav;