import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import foco from "./../../assets/icons/idea.png";
import fondos from "./../../assets/icons/fondos.png";
import ideasFondo from "./../../assets/icons/ideas-fondo.png";
import aliados from "./../../assets/icons/aliados.png";
import socio from "./../../assets/icons/socio.png";
import programas from "./../../assets/icons/programas.png";
import coworking from "./../../assets/icons/COWORKING.png";
import equipo from "./../../assets/icons/equipo.png";

const IdeasGanadoras = () =>{
    return(
        <>
            <div className="flex flex-wrap mt-20 lg:mt-52 container mx-auto py-8">
                <div data-aos="zoom-in" className="w-full lg:w-1/2 flex justify-center lg:justify-start items-center text-center lg:text-start">
                    <h1 className="text-5xl md:text-5xl font-bold text-white mb-10 lg:mb-0 md:ml-5">
                        <span className="text-orange-500">Ideas</span> 
                        <br />
                        ganadoras
                    </h1>
                </div>
                <div className="w-full lg:w-1/2 flex justify-center items-center text-center md:text-start ">
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-1/2 lg:w-2/6 p-1">
                            <div data-aos-duration="2000" data-aos="flip-left" className="p-5 bg-moradoClaro text-white flex flex-wrap h-full z-10">
                                <div className="w-full flex justify-center items-center">
                                    <img src={foco} className="w-1/4 md:w-1/2" />
                                </div>
                                <div className="w-full mt-3 text-center">
                                    Haremos realidad tu <span className="font-bold">idea</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-4/6 p-1">
                            <div data-aos-duration="2000" data-aos="flip-right" className="p-5 bg-moradoOscuro text-white flex flex-wrap h-full z-10">
                                <div className="w-full flex justify-center items-center">
                                    <img src={fondos} className="w-1/4" />
                                </div>
                                <div className="w-full mt-3 text-center">
                                    Acceso a <span className="font-bold">fondos</span> para compra de equipos, suministros y servicios esenciales para el <span className="font-bold">desarrollo de tu idea</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-1/3 lg:w-1/6 p-1">
                    <div data-aos-duration="2000" data-aos="flip-left" className="p-5 bg-moradoClaro text-white flex flex-wrap h-full z-10">
                        <div className="w-full flex justify-center">
                            <img src={ideasFondo} className="w-1/4 md:w-1/2" />
                        </div>
                        <div className="w-full mt-3 text-center">
                            <span className="font-bold">Invertiremos</span> en el presupuesto de tu <span className="font-bold">idea</span>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-1/3 lg:w-1/6 p-1">
                    <div data-aos-duration="2000" data-aos="flip-right" className="p-5 bg-moradoOscuro text-white flex flex-wrap h-full z-10">
                        <div className="w-full flex justify-center">
                            <img src={aliados} className="w-1/4 md:w-1/2" />
                        </div>
                        <div className="w-full mt-3 text-center">
                            <span className="font-bold">Perks</span> de <span className="font-bold">partners</span> y aliados para tu <span className="font-bold">idea</span>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-1/3 lg:w-1/6 p-1">
                    <div data-aos-duration="2000" data-aos="flip-left" className="p-5 bg-moradoClaro text-white flex flex-wrap h-full z-10">
                        <div className="w-full flex justify-center">
                            <img src={socio} className="w-1/4 md:w-1/2" />
                        </div>
                        <div className="w-full mt-3 text-center">
                            <span className="font-bold">Apalancamiento</span> como socios de <span className="font-bold">tu idea</span>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-1/3 lg:w-1/6 p-1">
                    <div data-aos-duration="2000" data-aos="flip-right" className="p-5 bg-moradoOscuro text-white flex flex-wrap h-full z-10">
                        <div className="w-full flex justify-center">
                            <img src={programas} className="w-1/4 md:w-1/2" />
                        </div>
                        <div className="w-full mt-3 text-center">
                        <span className="font-bold">Programa</span> de incubación de 6 meses para llevar a cabo <span className="font-bold">tu idea</span>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-1/3 lg:w-1/6 p-1">
                    <div data-aos-duration="2000" data-aos="flip-left" className="p-5 bg-moradoClaro text-white flex flex-wrap h-full z-10">
                        <div className="w-full flex justify-center">
                            <img src={coworking} className="w-1/4 md:w-1/2" />
                        </div>
                        <div className="w-full mt-3 text-center">
                            Acceso al <br /><span className="font-bold">co-working</span> de <span className="font-bold">Ticongle</span>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-1/3 lg:w-1/6 p-1">
                    <div data-aos-duration="2000" data-aos="flip-right" className="p-5 bg-moradoOscuro text-white flex flex-wrap h-full z-10">
                        <div className="w-full flex justify-center">
                            <img src={equipo} className="w-1/4 md:w-1/2" />
                        </div>
                        <div className="w-full mt-3 text-center">
                        Juntos llevaremos tu <span className="font-bold">idea</span> al siguiente <span className="font-bold">nivel</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IdeasGanadoras;