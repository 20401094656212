import { useEffect, useState } from "react"
import { useGlobalState } from "../GlobalState/GlobalState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import 'animate.css';

const ModalForm = () => {

    const { globalState, setGlobalState } = useGlobalState();

    const HideModal = () => {
        // Modifica el estado global
        setGlobalState({ ...globalState, valor: false });
    };

    return (
        <>
            {globalState.valor ? (
                <>
                    <div
                        className="justify-center py-10 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn"
                    >
                        <div className="relative w-full h-full mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Apúntate
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={HideModal}
                                    >
                                        <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            <FontAwesomeIcon icon={faX} />
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="text-slate-500 text-lg leading-relaxed h-full" >
                                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdwCjdDjfhcYFoj8bJMoL0VROjAosGr9oEB_UIJGmLjSSHjpQ/viewform?embedded=true" width="100%" height={"100%"} frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={HideModal}
                                    >
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}

export default ModalForm;