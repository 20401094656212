import Facebook from "./../../assets/icons/facebook-icon.png";
import Twitter from "./../../assets/icons/Twitter-icon.png";
import Instagram from "./../../assets/icons/instagram-icon.png";
import Discord from "./../../assets/icons/discord-icon.png";
import LinkedIn from "./../../assets/icons/linkedIn-icon.png";
import { faCopyright, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faFacebookF, faInstagram, faLinkedin, faTwitter, faXTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="bg-black text-center text-lg p-5 w-full text-white flex flex-wrap">
            <div className="w-full md:w-1/2 flex justify-center md:justify-start items-center">
                <p>Copyright <FontAwesomeIcon icon={faCopyright} /> {year} <span className="font-bold">HACKFEST</span> Hecho en El Salvador por <a target="_blank" href="https://www.conexion.sv/" className="text-moradoClaro font-bold">CONEXION</a></p>
            </div>
            <div className="w-full md:w-1/2 flex flex-wrap justify-center md:justify-end">
                <div className="w-full flex items-center justify-center md:justify-end">
                    <a href="https://www.facebook.com/conexion.sv" target="_blank" className="bg-white rounded-full z-0 flex justify-center items-center w-10 h-10 m-2">
                        <FontAwesomeIcon icon={faFacebookF} className="text-2xl z-10 text-black" />
                    </a>
                    <a href="https://www.instagram.com/conexion.sv/?hl=es" target="_blank" className="bg-white rounded-full z-0 flex justify-center items-center w-10 h-10 m-2">
                        <FontAwesomeIcon icon={faInstagram} className="text-2xl z-10 text-black" />
                    </a>
                    <a href="https://twitter.com/CONEXION_SV" target="_blank" className="bg-white rounded-full z-0 flex justify-center items-center w-10 h-10 m-2">
                        <FontAwesomeIcon icon={faXTwitter} className="text-2xl z-10 text-black" />
                    </a>
                    <a href="https://www.linkedin.com/company/conexion_sv" target="_blank" className="bg-white rounded-full z-0 flex justify-center items-center w-10 h-10 m-2">
                        <FontAwesomeIcon icon={faLinkedin} className="text-2xl z-10 text-black" />
                    </a>
                    <a href="https://discord.com/invite/Kgr94DxTNw" target="_blank" className="bg-white rounded-full z-0 flex justify-center items-center w-10 h-10 m-2">
                        <FontAwesomeIcon icon={faDiscord} className="text-2xl z-10 text-black" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;